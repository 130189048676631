import Vue from 'vue';
import Router from 'vue-router';
import WindowEventManager from '@/classes/WindowEventManager';
import Login from '@/pages/Login';
import ModuleSelect from '@/pages/ModuleSelect';
import SetPassword from '@/pages/SetPassword';
import ForgotPassword from '@/pages/ForgotPassword';
import EditProfile from '@/pages/EditProfile';

/* General */
import RoleOverview from '@/pages/general/settings/RoleOverview';
import RoleUpdate from '@/pages/general/settings/RoleUpdate';
import RoleLocationAccess from '@/pages/general/settings/RoleLocation';
import RoleLocationAccountAccess from '@/pages/general/settings/RoleLocationAccount';
import RoleModuleAccess from '@/pages/general/settings/RoleModuleAccess';
import UserOverview from '@/pages/general/settings/UserOverview';
import UserUpdate from '@/pages/general/settings/UserUpdate';
import ProfileUpdate from '@/pages/general/settings/ProfileUpdate';
import ConnectionOverview from '@/pages/general/settings/ConnectionOverview';
import Connection from '@/pages/general/settings/Connection';
import LocationOverview from '@/pages/general/settings/LocationOverview';
import LocationUpdate from '@/pages/general/settings/LocationUpdate';
import LocationMerchantSettingsUpdate from '@/pages/general/settings/LocationMerchantSettingsUpdate';
import ReturnsDashboard from '@/pages/general/controlTower/Dashboard.vue';
import Inventory from '@/pages/general/inventory/Dashboard.vue';
import InventoryAFD from '@/pages/general/inventory/DashboardAFD.vue';
import InventoryAFS from '@/pages/general/inventory/DashboardAFS.vue';
import InventoryContainerOverview from '@/pages/general/inventory/container/ContainerOverview';
import InventoryContainer from '@/pages/general/inventory/container/Container';
import InventoryContainerAssetOverview from '@/pages/general/inventory/container/ContainerAssetOverview';
import InventoryManifestOverview from '@/pages/general/inventory/manifest/ManifestOverview';
import InventoryManifest from '@/pages/general/inventory/manifest/Manifest';
import InventoryCompletedWorkOrderOverview from '@/pages/general/inventory/workOrder/CompletedWorkOrderOverview';
import InventoryWorkOrder from '@/pages/general/inventory/workOrder/WorkOrder';

/* Return */
import ReturnModuleSelect from '@/pages/return/ModuleSelect.vue';
import Inbound from '@/pages/return/inbound/Dashboard.vue';
import ReceiveReturn from '@/pages/return/inbound/receiveReturn/ReceiveReturn';
import AssetOverview from '@/pages/return/inbound/receiveReturn/AssetOverview';
import Unpack from '@/pages/return/inbound/receiveReturn/Unpack';
import AddUnexpectedProduct from '@/pages/return/inbound/receiveReturn/AddUnexpectedProduct';
import InspectAsset from '@/pages/return/inbound/receiveReturn/InspectAsset';
import GradeUnspecifiedReturnProducts from '@/pages/return/inbound/receiveReturn/GradeUnspecifiedReturnProducts';
import CompleteReturn from '@/pages/return/inbound/receiveReturn/CompleteReturn';
import SortToManifest from '@/pages/return/inbound/receiveToManifest/SortToManifest';
import ReceiveToManifest from '@/pages/return/inbound/receiveToManifest/ReceiveToManifest';
import AddReturnRemarks from '@/pages/return/inbound/receiveToManifest/AddReturnRemarks';
import CheckReturnAssetOverview from '@/pages/return/inbound/receiveToManifest/AssetOverview';
import CheckAsset from '@/pages/return/inbound/receiveToManifest/UnpackAsset';
import FinishCheckReturn from '@/pages/return/inbound/receiveToManifest/FinishCheckReturn';
import CheckReturnAddUnexpectedProduct from '@/pages/return/inbound/receiveToManifest/AddUnexpectedProduct';
import CreateUnspecifiedReturn from '@/pages/return/inbound/receiveToManifest/CreateUnspecifiedReturn';
import RegisterUnspecifiedReturnProducts from '@/pages/return/inbound/receiveToManifest/RegisterUnspecifiedReturnProducts';
import IncomingManifestOverview from '@/pages/return/inbound/viewIncomingManifest/IncomingManifestOverview';
import IncomingManifest from '@/pages/return/inbound/viewIncomingManifest/IncomingManifest';
import ReceiveIncomingManifest from '@/pages/return/inbound/receiveIncomingManifest/ReceiveIncomingManifest';
import ReceiveWorkOrder from '@/pages/return/inbound/receiveWorkOrder/ReceiveWorkOrder';
import Storage from '@/pages/return/storage/Dashboard.vue';
import StorageAFD from '@/pages/return/storage/DashboardAFD.vue';
import StorageAFS from '@/pages/return/storage/DashboardAFS.vue';
import ManifestOverview from '@/pages/return/storage/manifest/ManifestOverview';
import Manifest from '@/pages/return/storage/manifest/Manifest';
import MergeManifest from '@/pages/return/storage/manifest/MergeManifest';
import Outbound from '@/pages/return/outbound/Dashboard.vue';
import ShipManifest from '@/pages/return/outbound/manifest/ShipManifest';
import ShipmentOverview from '@/pages/return/outbound/shipment/ShipmentOverview';
import ShipShipment from '@/pages/return/outbound/shipment/ShipShipment';
import ShipWorkOrder from '@/pages/return/outbound/workOrder/ShipWorkOrder';
import ContainerOverview from '@/pages/return/storage/container/ContainerOverview';
import Container from '@/pages/return/storage/container/Container';
import ContainerAssetOverview from '@/pages/return/storage/container/ContainerAssetOverview';
import ShipContainer from '@/pages/return/outbound/container/ShipContainer';
import Asset from '@/pages/return/storage/asset/Asset';
import AssetSearch from '@/pages/return/storage/asset/AssetSearch';
import AssetRework from '@/pages/return/storage/asset/AssetRework';
import AssetRevalue from '@/pages/return/storage/asset/AssetRevalue';
import WorkOrderOverview from '@/pages/return/storage/workOrder/WorkOrderOverview';
import WorkOrder from '@/pages/return/storage/workOrder/WorkOrder';
import WorkOrderCreate from '@/pages/return/storage/workOrder/WorkOrderCreate';

/* Shipping */
import ShippingModuleSelect from '@/pages/shipping/ModuleSelect.vue';
import ShippingInboundDashboard from '@/pages/shipping/inbound/Dashboard.vue';
import ShippingStorageDashboard from '@/pages/shipping/storage/Dashboard.vue';
import ShippingOutboundDashboard from '@/pages/shipping/outbound/Dashboard.vue';
import ShippingReceiveToManifest from '@/pages/shipping/inbound/receiveToManifest/ReceiveToManifest.vue';
import ShipParcelManifest from '@/pages/shipping/outbound/parcelManifest/ShipParcelManifest.vue';
import ParcelShipmentOverview from '@/pages/shipping/outbound/parcelShipment/ParcelShipmentOverview.vue';
import ShipParcelShipment from '@/pages/shipping/outbound/parcelShipment/ShipParcelShipment.vue';
import ShippingManifestOverview from '@/pages/shipping/storage/manifest/ManifestOverview.vue';
import ShippingManifest from '@/pages/shipping/storage/manifest/Manifest.vue';
import ShippingMergeManifest from '@/pages/shipping/storage/manifest/MergeManifest.vue';

import store from '@/store';

Vue.use(Router);

var router = new Router({
    routes: [
        {
            path: '/',
            component: Login,
            meta: {
                loggedInRoute: '/moduleSelect'
            }
        },
        {
            path: '/login',
            component: Login,
            name: 'Login'
        },
        {
            path: '/moduleSelect',
            component: ModuleSelect,
            name: 'ModuleSelect'
        },
        {
            path: '/roleUpdate',
            component: RoleUpdate,
            name: 'RoleUpdate',
            meta: {
                section: 'general',
                module: 'settings',
                capability: 'settings'
            }
        },
        {
            path: '/roleLocationAccess',
            component: RoleLocationAccess,
            name: 'RoleLocationAccess',
            meta: {
                section: 'general',
                module: 'settings',
                capability: 'settings'
            }
        },
        {
            path: '/roleLocationAccountAccess',
            component: RoleLocationAccountAccess,
            name: 'RoleLocationAccountAccess',
            meta: {
                section: 'general',
                module: 'settings',
                capability: 'settings'
            }
        },
        {
            path: '/roleModuleAccess',
            component: RoleModuleAccess,
            name: 'RoleModuleAccess',
            meta: {
                section: 'general',
                module: 'settings',
                capability: 'settings'
            }
        },
        {
            path: '/roleOverview',
            component: RoleOverview,
            name: 'RoleOverview',
            meta: {
                section: 'general',
                module: 'settings',
                capability: 'settings'
            }
        },
        {
            path: '/userOverview',
            component: UserOverview,
            name: 'UserOverview',
            meta: {
                section: 'general',
                module: 'settings',
                capability: 'settings'
            }
        },
        {
            path: '/userUpdate',
            component: UserUpdate,
            name: 'UserUpdate',
            meta: {
                section: 'general',
                module: 'settings',
                capability: 'settings'
            }
        },
        {
            path: '/setPassword',
            component: SetPassword,
            name: 'SetPassword'
        },
        {
            path: '/dashboard',
            component: ReturnsDashboard,
            name: 'Dashboard',
            meta: {
                section: 'general',
                module: 'control tower',
                capability: 'control-tower'
            }
        },
        {
            path: '/forgotPassword',
            component: ForgotPassword,
            name: 'ForgotPassword'
        },
        {
            path: '/editProfile',
            component: EditProfile,
            name: 'EditProfile'
        },
        {
            path: '/inbound',
            component: Inbound,
            name: 'Inbound'
        },
        {
            path: '/inbound/receiveReturn',
            component: ReceiveReturn,
            name: 'ReceiveReturn',
            meta: {
                section: 'return',
                module: 'inbound',
                capability: 'receive-return'
            }
        },
        {
            path: '/inbound/assetOverview',
            component: AssetOverview,
            name: 'AssetOverview',
            meta: {
                section: 'return',
                module: 'inbound',
                capability: 'receive-return'
            }
        },
        {
            path: '/inbound/unpack',
            component: Unpack,
            name: 'Unpack',
            meta: {
                section: 'return',
                module: 'inbound',
                capability: 'receive-return'
            }
        },
        {
            path: '/inbound/addUnexpectedProduct',
            component: AddUnexpectedProduct,
            name: 'AddUnexpectedProduct',
            meta: {
                section: 'return',
                module: 'inbound',
                capability: 'receive-return'
            }
        },
        {
            path: '/inbound/inspect',
            component: InspectAsset,
            name: 'InspectAsset',
            meta: {
                section: 'return',
                module: 'inbound',
                capability: 'receive-return'
            }
        },
        {
            path: '/inbound/gradeUnspecifiedReturnProduct',
            component: GradeUnspecifiedReturnProducts,
            name: 'GradeUnspecifiedReturnProducts',
            meta: {
                section: 'return',
                module: 'inbound',
                capability: 'receive-return'
            }
        },
        {
            path: '/inbound/completeReturn',
            component: CompleteReturn,
            name: 'CompleteReturn',
            meta: {
                section: 'return',
                module: 'inbound',
                capability: 'receive-return'
            }
        },
        {
            path: '/inbound/sortToManifest',
            component: SortToManifest,
            name: 'SortToManifest',
            meta: {
                section: 'return',
                module: 'inbound',
                capability: 'sort-to-manifest'
            }
        },
        {
            path: '/inbound/receiveToManifest',
            component: ReceiveToManifest,
            name: 'ReceiveToManifest',
            meta: {
                section: 'return',
                module: 'inbound',
                capability: 'receive-to-manifest'
            }
        },
        {
            path: '/inbound/returnRemarks',
            component: AddReturnRemarks,
            name: 'AddReturnRemarks',
            meta: {
                section: 'return',
                module: 'inbound',
                capability: 'sort-to-manifest'
            }
        },
        {
            path: '/inbound/checkReturnAssetOverview',
            component: CheckReturnAssetOverview,
            name: 'CheckReturnAssetOverview',
            meta: {
                section: 'return',
                module: 'inbound',
                capabilityLogicalOperator: 'OR',
                capability: [
                    'receive-to-manifest',
                    'sort-to-manifest',
                ]
            }
        },
        {
            path: '/inbound/checkAsset',
            component: CheckAsset,
            name: 'CheckAsset',
            meta: {
                section: 'return',
                module: 'inbound',
                capabilityLogicalOperator: 'OR',
                capability: [
                    'receive-to-manifest',
                    'sort-to-manifest',
                ]
            }
        },
        {
            path: '/inbound/finishCheckReturn',
            component: FinishCheckReturn,
            name: 'FinishCheckReturn',
            meta: {
                section: 'return',
                module: 'inbound',
                capabilityLogicalOperator: 'OR',
                capability: [
                    'receive-to-manifest',
                    'sort-to-manifest',
                ]
            }
        },
        {
            path: '/inbound/checkReturnAddUnexpectedProduct',
            component: CheckReturnAddUnexpectedProduct,
            name: 'CheckReturnAddUnexpectedProduct',
            meta: {
                section: 'return',
                module: 'inbound',
                capabilityLogicalOperator: 'OR',
                capability: [
                    'receive-to-manifest',
                    'sort-to-manifest',
                ]
            }
        },
        {
            path: '/inbound/:flow/createUnspecifiedReturn',
            component: CreateUnspecifiedReturn,
            name: 'CreateUnspecifiedReturn',
            meta: {
                section: 'return',
                module: 'inbound',
                capabilityLogicalOperator: 'OR',
                capability: [
                    'receive-to-manifest',
                    'sort-to-manifest',
                ]
            }
        },
        {
            path: '/inbound/registerUnspecifiedReturnProducts',
            component: RegisterUnspecifiedReturnProducts,
            name: 'RegisterUnspecifiedReturnProducts',
            meta: {
                section: 'return',
                module: 'inbound',
                capabilityLogicalOperator: 'OR',
                capability: [
                    'receive-to-manifest',
                    'sort-to-manifest',
                ]
            }
        },
        {
            path: '/inbound/incomingManifestOverview',
            component: IncomingManifestOverview,
            name: 'IncomingManifestOverview',
            meta: {
                section: 'return',
                module: 'inbound',
                capability: 'view-incoming-unsorted-manifests'
            }
        },
        {
            path: '/inbound/incomingManifest',
            component: IncomingManifest,
            name: 'IncomingManifest',
            meta: {
                section: 'return',
                module: 'inbound',
                capability: 'view-incoming-unsorted-manifests'
            }
        },
        {
            path: '/inbound/receiveIncomingManifest',
            component: ReceiveIncomingManifest,
            name: 'ReceiveIncomingManifest',
            meta: {
                section: 'return',
                module: 'inbound',
                capability: 'receive-incoming-unsorted-manifests'
            }
        },
        {
            path: '/inbound/receiveWorkOrder',
            component: ReceiveWorkOrder,
            name: 'ReceiveWorkOrder',
            meta: {
                section: 'return',
                module: 'inbound',
                capability: 'receive-work-order'
            }
        },
        {
            path: '/storage',
            component: Storage,
            name: 'Storage'
        },
        {
            path: '/storage/AFD',
            component: StorageAFD,
            name: 'StorageAFD'
        },
        {
            path: '/storage/AFS',
            component: StorageAFS,
            name: 'StorageAFS'
        },
        {
            path: '/storage/manifestOverview',
            component: ManifestOverview,
            name: 'ManifestOverview',
            meta: {
                section: 'return',
                module: 'storage',
                capability: 'view-manifests'
            }
        },
        {
            path: '/storage/manifest',
            component: Manifest,
            name: 'Manifest',
            meta: {
                section: 'return',
                module: 'storage',
                capability: 'view-manifests'
            }
        },
        {
            path: '/storage/mergeManifest',
            component: MergeManifest,
            name: 'MergeManifest',
            meta: {
                section: 'return',
                module: 'storage',
                capability: 'view-manifests'
            }
        },
        {
            path: '/storage/workOrderOverview',
            component: WorkOrderOverview,
            name: 'WorkOrderOverview',
            meta: {
                section: 'return',
                module: 'storage',
                capability: 'view-work-orders'
            }
        },
        {
            path: '/storage/workOrder',
            component: WorkOrder,
            name: 'WorkOrder',
            meta: {
                section: 'return',
                module: 'storage',
                capability: 'view-work-orders'
            }
        },
        {
            path: '/storage/workOrderCreate',
            component: WorkOrderCreate,
            name: 'WorkOrderCreate',
            meta: {
                section: 'return',
                module: 'storage',
                capability: 'view-work-orders'
            }
        },
        {
            path: '/outbound',
            component: Outbound,
            name: 'Outbound'
        },
        {
            path: '/outbound/shipManifests',
            component: ShipManifest,
            name: 'ShipManifest',
            meta: {
                section: 'return',
                module: 'outbound',
                capabilityLogicalOperator: 'OR',
                capability: [
                    'ship-unsorted-manifests',
                    'ship-sorted-manifests'
                ]
            }
        },
        {
            path: '/outbound/shipmentOverview',
            component: ShipmentOverview,
            name: 'ShipmentOverview',
            meta: {
                section: 'return',
                module: 'outbound',
                capability: 'ship-shipments'
            }
        },
        {
            path: '/outbound/sendShipment',
            component: ShipShipment,
            name: 'ShipShipment',
            meta: {
                section: 'return',
                module: 'outbound',
                capability: 'ship-shipments'
            }
        },
        {
            path: '/outbound/shipWorkOrder',
            component: ShipWorkOrder,
            name: 'ShipWorkOrder',
            meta: {
                section: 'return',
                module: 'outbound',
                capability: 'ship-work-orders'
            }
        },
        {
            path: '/storage/:storage/containerOverview',
            component: ContainerOverview,
            name: 'ContainerOverview',
            meta: {
                section: 'return',
                module: 'storage',
                capability: 'view-containers'
            }
        },
        {
            path: '/storage/:storage/container',
            component: Container,
            name: 'Container',
            meta: {
                section: 'return',
                module: 'storage',
                capability: 'view-containers'
            }
        },
        {
            path: '/storage/:storage/containerAssetOverview',
            component: ContainerAssetOverview,
            name: 'ContainerAssetOverview',
            meta: {
                section: 'return',
                module: 'storage',
                capability: 'view-containers'
            }
        },
        {
            path: '/outbound/shipContainer',
            component: ShipContainer,
            name: 'ShipContainer',
            meta: {
                section: 'return',
                module: 'outbound',
                capability: 'ship-containers'
            }
        },
        {
            path: '/profile/update',
            component: ProfileUpdate,
            name: 'ProfileUpdate',
            meta: {
                section: 'general',
                module: 'settings',
                capability: 'settings'
            }
        },
        {
            path: '/connectionOverview',
            component: ConnectionOverview,
            name: 'ConnectionOverview',
            meta: {
                section: 'general',
                module: 'settings',
                capability: 'settings'
            }
        },
        {
            path: '/connection',
            component: Connection,
            name: 'Connection',
            meta: {
                section: 'general',
                module: 'settings',
                capability: 'settings'
            }
        },
        {
            path: '/locationOverview',
            component: LocationOverview,
            name: 'LocationOverview',
            meta: {
                section: 'general',
                module: 'settings',
                capability: 'settings'
            }
        },
        {
            path: '/locationUpdate',
            component: LocationUpdate,
            name: 'LocationUpdate',
            meta: {
                section: 'general',
                module: 'settings',
                capability: 'settings'
            }
        },
        {
            path: '/locationMerchantSettings/update',
            component: LocationMerchantSettingsUpdate,
            name: 'LocationMerchantSettingsUpdate',
            meta: {
                section: 'general',
                module: 'settings',
                capability: 'settings'
            }
        },
        {
            path: '/inventory',
            component: Inventory,
            name: 'Inventory',
            meta: {
                section: 'general',
                module: 'control tower',
                capability: 'control-tower'
            }
        },
        {
            path: '/inventory/AFD',
            component: InventoryAFD,
            name: 'InventoryAFD',
            meta: {
                section: 'general',
                module: 'control tower',
                capability: 'control-tower'
            }
        },
        {
            path: '/inventory/AFS',
            component: InventoryAFS,
            name: 'InventoryAFS',
            meta: {
                section: 'general',
                module: 'control tower',
                capability: 'control-tower'
            }
        },
        {
            path: '/inventory/:storage/containerOverview',
            component: InventoryContainerOverview,
            name: 'InventoryContainerOverview',
            meta: {
                section: 'general',
                module: 'control tower',
                capability: 'control-tower'
            }
        },
        {
            path: '/inventory/:storage/container',
            component: InventoryContainer,
            name: 'InventoryContainer',
            meta: {
                section: 'general',
                module: 'control tower',
                capability: 'control-tower'
            }
        },
        {
            path: '/inventory/:storage/containerAssetOverview',
            component: InventoryContainerAssetOverview,
            name: 'InventoryContainerAssetOverview',
            meta: {
                section: 'general',
                module: 'control tower',
                capability: 'control-tower'
            }
        },
        {
            path: '/inventory/manifestOverview',
            component: InventoryManifestOverview,
            name: 'InventoryManifestOverview',
            meta: {
                section: 'general',
                module: 'control tower',
                capability: 'control-tower'
            }
        },
        {
            path: '/inventory/manifest',
            component: InventoryManifest,
            name: 'InventoryManifest',
            meta: {
                section: 'general',
                module: 'control tower',
                capability: 'control-tower'
            }
        },
        {
            path: '/inventory/completedWorkOrderOverview',
            component: InventoryCompletedWorkOrderOverview,
            name: 'InventoryCompletedWorkOrderOverview',
            meta: {
                section: 'general',
                module: 'control tower',
                capability: 'control-tower'
            }
        },
        {
            path: '/inventory/workOrder',
            component: InventoryWorkOrder,
            name: 'InventoryWorkOrder',
            meta: {
                section: 'general',
                module: 'control tower',
                capability: 'control-tower'
            }
        },
        {
            path: '/storage/:storage/assetSearch',
            component: AssetSearch,
            name: 'AssetSearch',
            meta: {
                section: 'return',
                module: 'storage',
                capability: 'view-containers'
            }
        },
        {
            path: '/storage/:storage/asset',
            component: Asset,
            name: 'Asset',
            meta: {
                section: 'return',
                module: 'storage',
                capability: 'view-containers'
            }
        },
        {
            path: '/storage/:storage/assetRework',
            component: AssetRework,
            name: 'AssetRework',
            meta: {
                section: 'return',
                module: 'storage',
                capability: 'asset-disposition'
            }
        },
        {
            path: '/storage/:storage/assetRevalue',
            component: AssetRevalue,
            name: 'AssetRevalue',
            meta: {
                section: 'return',
                module: 'storage',
                capability: 'asset-disposition'
            }
        },
        {
            path: '/shipping/inbound/receiveToManifest',
            component: ShippingReceiveToManifest,
            name: 'ShippingReceiveToManifest',
            meta: {
                section: 'shipping',
                module: 'inbound',
                capability: 'receive-to-manifest'
            }
        },
        {
            path: '/shipping/outbound/shipManifests',
            component: ShipParcelManifest,
            name: 'ShipParcelManifest',
            meta: {
                section: 'shipping',
                module: 'outbound',
                capability: 'ship-manifests'
            }
        },
        {
            path: '/shipping/outbound/shipmentOverview',
            component: ParcelShipmentOverview,
            name: 'ParcelShipmentOverview',
            meta: {
                section: 'shipping',
                module: 'outbound',
                capability: 'ship-shipments'
            }
        },
        {
            path: '/shipping/outbound/sendShipment',
            component: ShipParcelShipment,
            name: 'ShipParcelShipment',
            meta: {
                section: 'shipping',
                module: 'outbound',
                capability: 'ship-manifests'
            }
        },
        {
            path: '/shipping/moduleSelect',
            component: ShippingModuleSelect,
            name: 'ShippingModuleSelect'
        },
        {
            path: '/return/moduleSelect',
            component: ReturnModuleSelect,
            name: 'ReturnModuleSelect'
        },
        {
            path: '/shipping/inbound',
            component: ShippingInboundDashboard,
            name: 'ShippingInboundDashboard'
        },
        {
            path: '/shipping/storage',
            component: ShippingStorageDashboard,
            name: 'ShippingStorageDashboard'
        },
        {
            path: '/shipping/outbound',
            component: ShippingOutboundDashboard,
            name: 'ShippingOutboundDashboard'
        },
        {
            path: '/shipping/storage/manifestOverview',
            component: ShippingManifestOverview,
            name: 'ShippingManifestOverview',
            meta: {
                section: 'shipping',
                module: 'storage',
                capability: 'view-manifests'
            }
        },
        {
            path: '/shipping/storage/manifest',
            component: ShippingManifest,
            name: 'ShippingManifest',
            meta: {
                section: 'shipping',
                module: 'storage',
                capability: 'view-manifests'
            }
        },
        {
            path: '/shipping/storage/mergeManifest',
            component: ShippingMergeManifest,
            name: 'ShippingMergeManifest',
            meta: {
                section: 'shipping',
                module: 'storage',
                capability: 'view-manifests'
            }
        },
    ]
});


router.beforeEach(async function (to, from, next) {
    /*
     * Clean events on document
    */
    WindowEventManager.instance.removeEventListener('keydown');

    let redirectExempts = ['Login', 'ForgotPassword', 'SetPassword'];

    if (!redirectExempts.includes(to.name)) {
        store.dispatch('users/getCurrentUser').then((data) => {
            if (data) {
                if (typeof to.meta.capability !== 'undefined') {
                    store.dispatch('roleAccess/getByType', {roleId: store.state.users.currentUser.ROLE_ID, type: 'role-module-access'}).then(() => {
                        let moduleAccess = store.state.roleAccess['role-module-access'][store.state.users.currentUser.ROLE_ID];

                        if (Array.isArray(to.meta.capability)) {
                            if (typeof to.meta.capabilityLogicalOperator === 'undefined') {
                                return next({path: '/'});
                            }

                            let access = false;

                            if (to.meta.capabilityLogicalOperator === 'OR') {
                                /* Check if the user has any of the capabilities */
                                for (let i in to.meta.capability) {
                                    const capability = to.meta.capability[i];

                                    if (moduleAccess?.[to.meta.section]?.[to.meta.module]?.[capability]?.ACCESS) {
                                        access = true;
                                    }
                                }
                            } else if (to.meta.capabilityLogicalOperator === 'AND') {
                                /* Check if the user has all of the capabilities */
                                access = true;

                                for (let i in to.meta.capability) {
                                    const capability = to.meta.capability[i];

                                    if (!moduleAccess?.[to.meta.section]?.[to.meta.module]?.[capability]?.ACCESS) {
                                        access = false;
                                    }
                                }
                            }

                            if (!access) {
                                return next({path: '/'});
                            }
                        } else if (!moduleAccess?.[to.meta.section]?.[to.meta.module]?.[to.meta.capability]?.ACCESS) {
                            return next({path: '/'});
                        }
                    });
                }

                if (typeof to.meta.loggedInRoute !== 'undefined') {
                    /* Set alternative route when a user comes to the same url but logged in */
                    return next({path: to.meta.loggedInRoute});
                } else {
                    return next();
                }
            } else {
                return next({path: '/login'});
            }
        });
    } else {
        return next();
    }
});

export default router;
