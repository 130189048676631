<template>
    <div>
        <HeaderBar />

        <main class="main">
            <form action="" @submit.prevent>
                <div class="scrollable-area">
                    <div class="scrollable-content">
                        <div class="container">
                            <table class="table-first-level">
                                <thead>
                                    <tr>
                                        <th>Manifests type</th>
                                        <th>Selected manifests</th>
                                        <th>Shipping destination</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td colspan="4">
                                            <table class="table-second-level">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            {{ manifestsType|capitalize }}
                                                        </th>

                                                        <th>
                                                            {{ Object.keys(selectedManifests).length }}
                                                        </th>

                                                        <th>
                                                            <template v-if="!isLoadingShippingDestinations">
                                                                {{ selectedCarrierLocationName }}
                                                            </template>
                                                        </th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <template v-if="isLoadingManifestsReadyToShip">
                                                        <tr>
                                                            <td colspan="4" class="py-3">
                                                                Loading manifests to ship...
                                                            </td>
                                                        </tr>
                                                    </template>

                                                    <template v-else-if="manifestArray.length === 0">
                                                        <tr>
                                                            <td colspan="4" class="py-3">
                                                                No manifests ready to ship.
                                                            </td>
                                                        </tr>
                                                    </template>

                                                    <template v-else>
                                                        <tr>
                                                            <td colspan="4">
                                                                <input type="text" placeholder="Scan manifest number"
                                                                       class="form-control lg" ref="scanManifestInput"
                                                                       v-model="manifestCode">
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td colspan="4" class="pt-0">
                                                                <div class="d-flex justify-content-between">
                                                                    <span>
                                                                        <template
                                                                            v-if="manifestCode || showSelectedManifestsOnly">
                                                                            Showing {{ filteredManifestsReadyToShip.length }} of {{ manifestArray.length }} {{ manifestsType }} manifests
                                                                        </template>

                                                                        <template v-else>
                                                                            Showing all {{ manifestsType }} manifests
                                                                        </template>
                                                                    </span>

                                                                    <label class="checkbox-container mr-3">
                                                                        <input type="checkbox" class="form-control"
                                                                               v-model="showSelectedManifestsOnly">
                                                                        <span class="checkmark"></span>

                                                                        Only show selected manifests
                                                                    </label>
                                                                </div>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td colspan="4" class="py-0">
                                                                <InputFieldError attribute="shipment" :errors="errors"/>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td colspan="6" class="pt-5 pb-3">
                                                                <label class="checkbox-container mb-0 ml-4"
                                                                       @click.prevent="toggleAllCheckboxes()">
                                                                    Select all

                                                                    <input type="checkbox" v-model="selectedAll"
                                                                           :disabled="isCreatingShipment">
                                                                    <span class="checkmark"></span>
                                                                </label>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td colspan="4" class="pt-0">
                                                                <table class="table-third-level">
                                                                    <thead>
                                                                        <tr>
                                                                            <th class="w-20 pr-0"></th>

                                                                            <th class="w-auto pl-0">
                                                                                Manifest
                                                                            </th>

                                                                            <th class="w-auto">
                                                                                Manifest ID
                                                                            </th>

                                                                            <th class="w-auto">
                                                                                Parcels
                                                                            </th>

                                                                            <th class="w-auto"></th>
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody>
                                                                        <tr v-for="manifest in filteredManifestsReadyToShip"
                                                                            :key="manifest.ID"
                                                                        >
                                                                            <td class="w-20 pr-0">
                                                                                <label
                                                                                    class="checkbox-container mb-20 mr-1">
                                                                                    <input type="checkbox"
                                                                                           v-model="selectedParcelManifests[manifest.ID]"
                                                                                           :disabled="isCreatingShipment"
                                                                                    >
                                                                                    <span class="checkmark"></span>
                                                                                </label>
                                                                            </td>

                                                                            <td class="w-auto pl-0">
                                                                                {{ manifest.MANIFEST_CODE }}
                                                                            </td>

                                                                            <td class="w-auto">
                                                                                {{ manifest.ID }}
                                                                            </td>

                                                                            <td class="w-auto">
                                                                                {{ manifest.parcelManifestRows.length }}
                                                                            </td>

                                                                            <td class="w-auto">
                                                                                <template
                                                                                    v-if="manifestDocumentsBeingGenerated && manifestDocumentsBeingGenerated.includes(manifest.ID)">
                                                                                    Generating manifest list...
                                                                                </template>

                                                                                <template v-else>
                                                                                    <a href="#"
                                                                                       @click.prevent="getManifestListPdf(manifest.ID)">
                                                                                        Manifest list
                                                                                    </a>
                                                                                </template>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </template>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div><!-- scrollable-content -->

                    <div class="section-footer">
                        <div class="container">
                            <div class="d-flex justify-content-between align-items-center">
                                <router-link :to="{name: 'ShippingOutboundDashboard'}" class="btn btn-secondary">
                                    Back
                                </router-link>

                                <div v-if="isCreatingShipment">
                                    Creating shipment...
                                </div>

                                <div v-else-if="isGeneratingDocument">
                                    Generating shipment document...
                                </div>

                                <button type="button"
                                        class="btn btn-primary"
                                        @click="createShipment()"
                                        :disabled="!Object.keys(selectedManifests).length || !selectedShippingDestination?.ID || isCreatingShipment">
                                    Create shipment
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </main>
    </div>
</template>

<script>
import HeaderBar from '@/components/_HeaderBar.vue';
import InputFieldError from '@/components/_InputFieldError.vue';

export default {
    components: {
        'HeaderBar': HeaderBar,
        'InputFieldError': InputFieldError,
    },
    data() {
        return {
            errors: {},
            isLoadingManifestsReadyToShip: false,
            isLoadingShippingDestinations: false,
            isCreatingShipment: false,
            isGeneratingDocument: false,
            manifestDocumentsBeingGenerated: [],
            manifestsReadyToShip: null,
            manifestCode: null,
            selectedParcelManifests: {},
            showSelectedManifestsOnly: false,
            shippingDestinations: [],
            selectedLocation: null,
            moduleAccess: {},
            canShipShipments: false,
        };
    },
    mounted() {
        this.$root.$on('loadedRoleModuleAccess', () => {
            this.moduleAccess = this.$store.state.roleAccess?.['role-module-access']?.[this.$store.state.users.currentUser.ROLE_ID]?.['shipping']?.['outbound'];
            this.canShipShipments = typeof this.moduleAccess['ship-shipments'] !== 'undefined' && this.moduleAccess['ship-shipments'].ACCESS;
        });
    },
    created() {
        this.getManifestsReadyToShip();
        this.getCarrierLocations();
    },
    methods: {
        getManifestsReadyToShip() {
            this.isLoadingManifestsReadyToShip = true;
            const url = new URL(this.$store.state.baseUrl + 'parcel-manifests/get-all-ready-to-ship');
            url.searchParams.set('carrierLocationId', this.$route.query.carrierLocationId);

            this.manifestsReadyToShip = null;

            this.$http.get(url.href).then((data) => {
                this.isLoadingManifestsReadyToShip = false;
                this.manifestsReadyToShip = data.data;

                this.$nextTick(() => {
                    if (this.$refs.scanManifestInput) {
                        this.$refs.scanManifestInput.focus();
                    }
                });
            });
        },
        getCarrierLocations() {
            this.isLoadingShippingDestinations = true;

            const url = new URL(this.$store.state.baseUrl + 'locations/get-all');
            url.searchParams.set('locationType', 'carrier');

            this.$http.get(url.href).then((data) => {
                this.isLoadingShippingDestinations = false;
                this.carrierLocationOptions = data?.data ?? [];
            });
        },
        createShipment() {
            this.errors = {};

            if (this.selectedManifests.length === 0 || !this.selectedShippingDestination?.ID) {
                return;
            }

            let selectedManifestIds = [];

            for (let i in this.selectedManifests) {
                selectedManifestIds.push(this.selectedManifests[i].ID);
            }

            this.isCreatingShipment = true;

            const url = new URL(this.$store.state.baseUrl + 'parcel-shipments/create-parcel-shipment');
            const params = new URLSearchParams();
            params.append('ParcelShipment[modelIds]', selectedManifestIds);
            params.append('ParcelShipment[type]', this.manifestsType === 'sorted' ? 'sorted-parcel-manifest' : 'unsorted-parcel-manifest');

            params.append('ParcelShipment[destinationCarrierLocationId]', this.selectedShippingDestination.ID);
            params.append('ParcelShipment[DESTINATION_COUNTRY]', this.selectedShippingDestination.locationAddress.COUNTRY);
            params.append('ParcelShipment[DESTINATION_CITY]', this.selectedShippingDestination.locationAddress.CITY);
            params.append('ParcelShipment[DESTINATION_NAME]', this.selectedShippingDestination.NAME);

            if (this.selectedShippingDestination.locationAddress.ADDRESSEE) {
                params.append('ParcelShipment[DESTINATION_ADDRESSEE]', this.selectedShippingDestination.locationAddress.ADDRESSEE);
            }

            if (this.selectedShippingDestination.locationAddress.POSTAL) {
                params.append('ParcelShipment[DESTINATION_POSTAL_CODE]', this.selectedShippingDestination.locationAddress.POSTAL);
            }

            if (this.selectedShippingDestination.locationAddress.ADMINISTRATIVE_AREA) {
                params.append('ParcelShipment[DESTINATION_ADMINISTRATIVE_AREA]', this.selectedShippingDestination.locationAddress.ADMINISTRATIVE_AREA);
            }

            if (this.selectedShippingDestination.locationAddress.ADDRESS_LINE_1) {
                params.append('ParcelShipment[DESTINATION_ADDRESS_LINE_1]', this.selectedShippingDestination.locationAddress.ADDRESS_LINE_1);
            }

            if (this.selectedShippingDestination.locationAddress.ADDRESS_LINE_2) {
                params.append('ParcelShipment[DESTINATION_ADDRESS_LINE_2]', this.selectedShippingDestination.locationAddress.ADDRESS_LINE_2);
            }

            this.$http.post(url.href, params).then((data) => {
                this.isCreatingShipment = false;

                if (data.data.code === 422) {
                    this.$set(this.errors, 'shipment', data.data.errors);
                } else if (data.data.code === 100) {
                    this.$store.commit('showSuccessMessage', 'The shipment has been created.');

                    this.getShipmentPdf(data.data.data);

                    if (this.canShipShipments) {
                        this.$router.push({
                            name: 'ShipParcelShipment',
                            query: {
                                reference: data.data.data,
                                origin: 'shipSorted'
                            }
                        });
                    } else {
                        this.selectedParcelManifests = {};
                        this.getManifestsReadyToShip();
                    }
                }
            });
        },
        getManifestListPdf(manifestId) {
            this.errors = {};
            this.manifestDocumentsBeingGenerated.push(manifestId);

            const url = new URL(this.$store.state.baseUrl + 'pdf/parcel-manifest');
            url.searchParams.append('id', manifestId);

            this.$http.get(url.href, {responseType: 'blob'}).then((data) => {
                let index = this.manifestDocumentsBeingGenerated.indexOf(manifestId);
                this.manifestDocumentsBeingGenerated.splice(index, 1);

                let containerDocumentBlob = new Blob([data.data], {type: 'application/pdf'});
                window.open(URL.createObjectURL(containerDocumentBlob));
            });
        },
        getShipmentPdf(shipmentId) {
            this.errors = {};
            this.isGeneratingDocument = true;

            const url = new URL(this.$store.state.baseUrl + 'pdf/outbound-parcel-shipment');
            url.searchParams.append('id', shipmentId);

            this.$http.get(url.href, {responseType: 'blob'}).then((data) => {
                this.isGeneratingDocument = false;

                const documentBlob = new Blob([data.data], {type: 'application/pdf'});
                window.open(URL.createObjectURL(documentBlob));
            });
        },
        toggleAllCheckboxes() {
            if (this.isCreatingShipment) {
                return;
            }

            if (this.filteredManifestsReadyToShip) {
                const isSelected = this.selectedAll;

                for (let i = 0; i < Object.keys(this.filteredManifestsReadyToShip).length; i++) {
                    const key = this.filteredManifestsReadyToShip[i].ID;
                    this.$set(this.selectedParcelManifests, key, !isSelected);
                }
            }
        }
    },
    computed: {
        manifestArray() {
            if (!this.manifestsReadyToShip) {
                return [];
            }

            let result = [];

            for (let i in this.manifestsReadyToShip) {
                const manifest = this.manifestsReadyToShip[i];

                if (manifest.SORTING_TYPE !== this.manifestsType) {
                    continue;
                }

                if (this.manifestsType === 'sorted' && manifest.MERCHANT_ACCOUNT_ID !== this.$route.query.merchant) {
                    continue;
                }

                result.push(manifest);
            }

            return result;
        },
        filteredManifestsReadyToShip() {
            if (!this.manifestArray || this.manifestArray.length === 0) {
                return [];
            }

            let result = [];

            for (let i in this.manifestArray) {
                const manifest = this.manifestArray[i];

                if (this.showSelectedManifestsOnly && !this.selectedManifests.includes(manifest)) {
                    continue;
                }

                if (this.manifestCode && !manifest.MANIFEST_CODE.toLowerCase().includes(this.manifestCode.toLowerCase()) && !manifest.ID.includes(this.manifestCode)) {
                    continue;
                }

                result.push(manifest);
            }

            return result;
        },
        selectedManifests() {
            if (Object.keys(this.selectedParcelManifests).length === 0) {
                return [];
            }

            let result = [];

            for (let i = 0; i < Object.keys(this.selectedParcelManifests).length; i++) {
                const key = Object.keys(this.selectedParcelManifests)[i];
                const value = Object.values(this.selectedParcelManifests)[i];

                if (value) {
                    const manifest = this.manifestsReadyToShip.find(manifest => manifest.ID == key);

                    if (manifest) {
                        result.push(manifest);
                    }
                }
            }

            return result;
        },
        manifestsType() {
            let result = '';

            if (this.$route.query.type) {
                if (this.$route.query.type === 'unsorted') {
                    result = 'unsorted';
                } else if (this.$route.query.type === 'sorted') {
                    result = 'sorted';
                }
            }

            return result;
        },
        selectedShippingDestination() {
            return this.carrierLocationOptions?.find((option) => option.ID === this.$route.query.carrierLocationId) ?? null;
        },
        selectedAll() {
            return this.filteredManifestsReadyToShip.length > 0 && this.filteredManifestsReadyToShip.length === this.selectedManifests.length;
        },
        selectedCarrierLocationName() {
            return this.carrierLocationOptions?.find((option) => option.ID === this.$route.query.carrierLocationId)?.NAME ?? '';
        }
    },
    filters: {
        capitalize: function (value) {
            if (!value) return '';
            value = value.toString();
            return value.charAt(0).toUpperCase() + value.slice(1);
        },
    }
}
</script>

<style scoped>
.error-text {
    color: red;
}
</style>
