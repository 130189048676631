<template>
    <div>
        <HeaderBar></HeaderBar>

        <main class="main">
            <div class="scrollable-area">
                <div class="scrollable-content">
                    <div class="container">
                        <form v-on:submit.prevent="scanManifest()">
                            <div class="form-group mt-5">
                                <label for="rma-input" class="label-title">
                                    Scan to receive incoming manifest
                                </label>

                                <input type="text" placeholder="Scan manifest number" class="form-control lg"
                                       :disabled="isLoadingManifest || isReceivingReturn"
                                       v-model="manifestCode" ref="scanManifestInput">
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <InputFieldError attribute="manifestCode" :errors="errors"/>
                                </div>

                                <div class="col-12" v-if="isLoadingManifest">
                                    <p>Loading manifest {{ manifestCode }}...</p>
                                </div>
                            </div>
                        </form>

                        <form v-on:submit.prevent="receiveReturnFromManifest()">
                            <div class="form-group">
                                <label for="rma-input" class="label-title">
                                    Scan to receive return from incoming manifest
                                </label>

                                <input type="text" placeholder="Scan RMA number or tracking code" class="form-control lg"
                                       id="rma-input" v-model="returnReference" ref="scanRmaInput"
                                       :disabled="isLoadingManifest || isReceivingReturn || !manifest">
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <InputFieldError attribute="returnReference" :errors="errors"/>
                                </div>

                                <div class="col-12" v-if="isReceivingReturn">
                                    <p>Receiving return {{ returnReference }}...</p>
                                </div>
                            </div>
                        </form>

                        <div class="row" v-if="manifest">
                            <div class="col-12">
                                <table class="table-first-level mb-4">
                                    <thead>
                                        <tr>
                                            <th>Manifest</th>
                                            <th>Manifest ID</th>
                                            <th>First tier location</th>
                                            <th>Sorting type</th>
                                            <th>Received returns</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td colspan="5">
                                                <table class="table-second-level">
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                {{ manifest.MANIFEST_CODE }}
                                                            </th>

                                                            <th>
                                                                {{ manifest.ID }}
                                                            </th>

                                                            <th>
                                                                <template v-if="manifest.location && manifest.location.NAME">
                                                                    {{ manifest.location.NAME }}
                                                                </template>

                                                                <template v-else>
                                                                    -
                                                                </template>
                                                            </th>

                                                            <th>
                                                                <template v-if="manifest.SORTING_TYPE">
                                                                    {{ manifest.SORTING_TYPE | capitalize }}
                                                                </template>

                                                                <template v-else>
                                                                    -
                                                                </template>
                                                            </th>

                                                            <th>
                                                                {{ Object.keys(manifest.receivedReturnManifestRows).length }}

                                                                of

                                                                {{ Object.keys(manifest.shippedReturnManifestRows).length }}
                                                            </th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        <tr>
                                                            <td colspan="5" class="card-container">
                                                                <div class="dashboard-card-horizontal receive-returns">
                                                                    <div class="dashboard-card-horizontal-body">
                                                                        <div class="row">
                                                                            <div class="col-12 col-sm-6">
                                                                                <div class="list-left">
                                                                                    <h5>
                                                                                        <span>Expected</span>
                                                                                    </h5>

                                                                                    <ul>
                                                                                        <li v-for="(returnManifestRow, key) in manifest.unreceivedReturnManifestRows" v-bind:key="key">
                                                                                            <div class="dashboard-card-horizontal-left">
                                                                                                <span>
                                                                                                    <template v-if="returnManifestRow.RETURN_REFERENCE">
                                                                                                        {{ returnManifestRow.RETURN_REFERENCE }}
                                                                                                    </template>

                                                                                                    <template v-else>
                                                                                                        {{ returnManifestRow.SHIPMENT_TRACKING_CODE }}
                                                                                                    </template>
                                                                                                </span>

                                                                                                <span v-if="returnManifestRow.RETURN_EXTERNAL_REFERENCE" class="external-reference ml-3">
                                                                                                    {{ returnManifestRow.RETURN_EXTERNAL_REFERENCE }}
                                                                                                </span>
                                                                                            </div>
                                                                                        </li>

                                                                                        <li v-if="!manifest.unreceivedReturnManifestRows || Object.keys(manifest.unreceivedReturnManifestRows).length === 0">
                                                                                            There are no unreceived returns.
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>

                                                                            <div class="col-12 col-sm-6">
                                                                                <div class="list-right">
                                                                                    <h5>
                                                                                        <span>Received</span>
                                                                                    </h5>

                                                                                    <ul>
                                                                                        <li v-for="(returnManifestRow, key) in orderedReceivedReturnManifestRows" v-bind:key="key">
                                                                                            <div class="dashboard-card-horizontal-left">
                                                                                                <span>
                                                                                                    <template v-if="returnManifestRow.RETURN_REFERENCE">
                                                                                                        {{ returnManifestRow.RETURN_REFERENCE }}
                                                                                                    </template>

                                                                                                    <template v-else>
                                                                                                        {{ returnManifestRow.SHIPMENT_TRACKING_CODE }}
                                                                                                    </template>
                                                                                                </span>

                                                                                                <span v-if="returnManifestRow.RETURN_EXTERNAL_REFERENCE" class="external-reference ml-3">
                                                                                                    {{ returnManifestRow.RETURN_EXTERNAL_REFERENCE }}
                                                                                                </span>
                                                                                            </div>
                                                                                        </li>

                                                                                        <li v-if="!manifest.receivedReturnManifestRows || manifest.receivedReturnManifestRows.length === 0">
                                                                                            There are no received returns.
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div><!-- dashboard-card-horizontal-body -->
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <div class="d-flex justify-content-between mb-5">
                                    <button type="button" class="btn btn-primary p-3" @click.prevent="getManifestListPdf()" :disabled="isGeneratingDocument || isClosingManifest">
                                        Manifest list
                                    </button>

                                    <div v-if="errors && Object.keys(errors).length > 0">
                                        <InputFieldError attribute="closingManifest" :errors="errors"/>
                                    </div>

                                    <div class="align-self-center" v-else-if="isGeneratingDocument">
                                        Generating shipping document...
                                    </div>

                                    <div class="align-self-center" v-else-if="isClosingManifest">
                                        Closing manifest...
                                    </div>

                                    <button type="button" class="btn btn-primary p-3" @click.prevent="showConfirmationModal = true" :disabled="isGeneratingDocument || isClosingManifest">
                                        Close manifest
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="section-footer">
                    <div class="container">
                        <div class="d-flex justify-content-between">
                            <button type="button" class="btn btn-secondary" @click="previous()">
                                Back
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </main>

        <ConfirmationPopup v-if="showConfirmationModal"
                           :message="'Are you sure you want to close manifest ' + manifest.MANIFEST_CODE + '?'"
                           @deny="showConfirmationModal = false" @confirm="closeManifest()"></ConfirmationPopup>
    </div>
</template>

<script>
    import HeaderBar from '@/components/_HeaderBar.vue';
    import InputFieldError from '@/components/_InputFieldError.vue';
    import _ from 'lodash';
    import ConfirmationPopup from '@/components/_ConfirmationPopup.vue';

    export default {
        components: {
            'HeaderBar': HeaderBar,
            'InputFieldError': InputFieldError,
            'ConfirmationPopup': ConfirmationPopup,
        },
        data() {
            return {
                isLoadingManifest: false,
                isReceivingReturn: false,
                errors: {},
                returnReference: null,
                manifest: null,
                manifestCode: null,
                manifestId: this.$route.query.manifestId,
                manifestReference: this.$route.query.manifestReference,
                selectedLocation: null,
                moduleAccess: {},
                canReceiveReturn: false,
                isGeneratingDocument: false,
                isClosingManifest: false,
                showConfirmationModal: false,
            };
        },
        mounted() {
            /* Focus on the "Scan manifest" input field, so the user can immediately enter a manifest code on page load */
            this.$refs.scanManifestInput.focus();

            this.$root.$on('loadedRoleModuleAccess', () => {
                this.moduleAccess = this.$store.state.roleAccess?.['role-module-access']?.[this.$store.state.users.currentUser.ROLE_ID]?.['return']?.['inbound'];
                this.canReceiveReturn = typeof this.moduleAccess['receive-return'] !== 'undefined' && this.moduleAccess['receive-return'].ACCESS;
            });
        },
        created() {
            this.$store.dispatch('userSettings/getSelectedLocation').then((data) => {
                if (data && data.value) {
                    this.selectedLocation = data.value;

                    if (this.manifestId && this.manifestReference) {
                        this.preloadManifest();
                    }
                }
            });
        },
        methods: {
            preloadManifest() {
                this.manifestCode = this.manifestReference;
                this.scanManifest(true);
            },
            scanManifest(loadByManifestId = false) {
                this.errors = {};
                this.manifest = null;

                /* Check if the manifest code is empty, or exists of only spaces */
                if (!this.manifestCode || /^\s*$/.test(this.manifestCode)) {
                    this.errors = {manifestCode: 'Enter a manifest code!'};
                    return false;
                }

                this.isLoadingManifest = true;

                let url;
                let method;

                if (loadByManifestId) {
                    url = new URL(this.$store.state.baseUrl + 'return-manifests/get-incoming');
                    url.searchParams.append('manifestCode', this.manifestId);
                    url.searchParams.append('destinationLocationId', this.selectedLocation);
                    method = this.$http.get(url.href);
                } else {
                    url = new URL(this.$store.state.baseUrl + 'return-manifests/receive-incoming-manifest');
                    let params = new URLSearchParams();
                    params.append('ReturnManifest[MANIFEST_CODE]', this.manifestCode);
                    method = this.$http.post(url.href, params);
                }

                method.then((data) => {
                    this.isLoadingManifest = false;

                    if (data.data.code === 422) {
                        this.errors = {manifestCode: data.data.errors};
                        return false;
                    }

                    this.manifest = data.data.data;

                    this.$nextTick(() => {
                        this.$refs.scanRmaInput.focus();
                    });
                });
            },
            previous() {
                this.$router.push({
                    name: 'Inbound',
                });
            },
            receiveReturnFromManifest() {
                this.errors = {};
                this.isReceivingReturn = true;

                const key = _.findIndex(this.manifest.unreceivedReturnManifestRows, (row) => {
                    return row.RETURN_REFERENCE === this.returnReference || row.SHIPMENT_TRACKING_CODE === this.returnReference;
                });

                if (!this.manifest.unreceivedReturnManifestRows[key]) {
                    this.isReceivingReturn = false;
                    this.errors = {returnReference: 'Unable to scan return with reference "' + this.returnReference + '"'};
                    return false;
                }

                /*
                 * receiveSorted when the manifest is of type 'sorted' and the return is known in Returnbird and the account & role have access to the container flow.
                 * receiveUnsorted if the manifest is of type 'unsorted' or if the return is unknown in Returnbird.
                 */
                if (this.manifest.SORTING_TYPE && this.manifest.SORTING_TYPE === 'sorted' && this.manifest.unreceivedReturnManifestRows[key].RETURN_ID && this.canReceiveReturn) {
                    this.receiveSorted(this.manifest.unreceivedReturnManifestRows[key].RETURN_ID);
                } else {
                    this.receiveUnsorted();
                }
            },
            receiveSorted(returnId) {
                const url = new URL(this.$store.state.baseUrl + 'returns/find-incoming-by-id');
                url.searchParams.append('returnId', returnId);

                this.$http.get(url.href).then((data) => {
                    this.isReceivingReturn = false;

                    if (data.data.code === 100) {
                        this.$router.push({
                            name: 'AssetOverview',
                            query: {
                                returnId: returnId,
                                flow: 'incomingManifest',
                                manifestId: this.manifest.ID,
                                manifestReference: this.manifest.MANIFEST_CODE,
                            }
                        });
                    } else if (data.data.code === 422) {
                        this.errors = {returnReference: data.data.errors};
                    } else {
                        this.errors = {returnReference: 'Something went wrong while receiving return with reference "' + this.returnReference + '"'};
                    }
                });
            },
            receiveUnsorted() {
                const url = new URL(this.$store.state.baseUrl + 'return-manifests/receive-incoming-return');
                let params = new URLSearchParams();
                params.append('ReturnManifest[RETURN_REFERENCE]', this.returnReference);
                params.append('ReturnManifest[MANIFEST_ID]', this.manifest.ID);
                params.append('ReturnManifest[destinationLocationId]', this.selectedLocation);

                this.$http.post(url.href, params).then((data) => {
                    this.isReceivingReturn = false;

                    if (data.data.code === 422) {
                        this.errors = {returnReference: data.data.errors};
                        return false;
                    }

                    if (Object.keys(this.manifest.receivedReturnManifestRows).length === 0) {
                        this.manifest.receivedReturnManifestRows = [];
                    }

                    /* Move the return from "Expected" to "Received", and add the received timestamp. */
                    const key = _.findIndex(this.manifest.unreceivedReturnManifestRows, (row) => {
                        return row.RETURN_REFERENCE === this.returnReference || row.SHIPMENT_TRACKING_CODE === this.returnReference;
                    });

                    if (key !== -1) {
                        this.$set(this.manifest.unreceivedReturnManifestRows[key], 'RECEIVED_TIMESTAMP', data.data.data.RECEIVED_TIMESTAMP);
                        this.manifest.receivedReturnManifestRows.push(this.manifest.unreceivedReturnManifestRows[key]);
                        this.$delete(this.manifest.unreceivedReturnManifestRows, key);
                    }

                    this.returnReference = null;

                    this.$nextTick(() => {
                        this.$refs.scanRmaInput.focus();
                    });
                });
            },
            getManifestListPdf() {
                this.errors = {};
                this.isGeneratingDocument = true;

                const url = new URL(this.$store.state.baseUrl + 'pdf/incoming-manifest');
                url.searchParams.append('id', this.manifest.ID);
                url.searchParams.append('destinationLocationId', this.selectedLocation);

                this.$http.get(url.href, {responseType: 'blob'}).then((data) => {
                    this.isGeneratingDocument = false;

                    let containerDocumentBlob = new Blob([data.data], {type: 'application/pdf'});
                    window.open(URL.createObjectURL(containerDocumentBlob));
                });
            },
            closeManifest() {
                this.errors = {};
                this.isClosingManifest = true;
                this.showConfirmationModal = false;

                const url = new URL(this.$store.state.baseUrl + 'return-manifests/close-manifest');
                const params = new URLSearchParams();

                params.append('ReturnManifest[ID]', this.manifest.ID);
                params.append('ReturnManifest[destinationLocationId]', this.selectedLocation);

                this.$http.post(url.href, params).then((data) => {
                    this.isClosingManifest = false;

                    if (data.data.code === 100) {
                        this.manifest = null;
                        this.manifestCode = null;
                        this.manifestId = null;
                        this.manifestReference = null;
                        this.returnReference = null;
                        this.errors = {};

                        /* Clear query string */
                        if (this.$route.query.manifestId) {
                            this.$router.push({
                                name: 'ReceiveIncomingManifest'
                            });
                        }

                        this.$nextTick(() => {
                            this.$refs.scanManifestInput.focus();
                        });
                    } else {
                        this.errors = {closingManifest: data.data.errors};
                        return false;
                    }
                });
            }
        },
        computed: {
            orderedReceivedReturnManifestRows() {
                return _.orderBy(this.manifest.receivedReturnManifestRows, 'RECEIVED_TIMESTAMP', 'desc');
            }
        },
        filters: {
            capitalize: function (value) {
                if (!value) return '';
                value = value.toString();
                return value.charAt(0).toUpperCase() + value.slice(1);
            }
        },
        watch: {
            manifestCode() {
                /* Unset this.manifest to force the operator to load the new manifest after changing the manifest code. */
                if (this.manifest) {
                    this.manifest = null;
                    this.returnReference = null;
                    this.errors = {};
                }
            }
        }
    }
</script>

<style scoped>
    .scrollable-content {
        padding: 30px 0;
    }
</style>
