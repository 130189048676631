<template>
    <div>
        <HeaderBar/>

        <main class="main">
            <div class="scrollable-area">
                <div class="scrollable-content">
                    <div class="container">
                        <form @submit.prevent="scanReference()">
                            <div class="form-group mb-0">
                                <label for="rma-input" class="label-title">
                                    Scan to receive parcel to manifest
                                </label>

                                <input type="text"
                                       placeholder="Scan parcel shipment tracking code"
                                       class="form-control lg"
                                       id="rma-input"
                                       v-model="parcelReference"
                                       ref="scanReferenceInput"
                                       :disabled="isLoading || isSaving"
                                >
                            </div>

                            <div class="row my-3">
                                <div class="col-12">
                                    <InputFieldError attribute="parcelReference" :errors="errors"/>
                                </div>
                            </div>
                        </form>

                        <form @submit.prevent="scanManifest()">
                            <div class="form-group mb-0">
                                <input type="text"
                                       placeholder="Scan manifest number"
                                       class="form-control lg"
                                       :disabled="!scanManifestInputActive || isSaving"
                                       v-model="manifestCode"
                                       ref="scanManifestInput"
                                >
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <InputFieldError attribute="manifestCode" :errors="errors"/>
                                </div>
                            </div>
                        </form>

                        <p class="saving-message" v-if="isSaving">
                            Adding parcel {{ parcelReference }} to manifest {{ manifestCode }}...
                        </p>

                        <template v-if="recentlyAddedItems">
                            <p v-for="(value, key) in recentlyAddedItems"
                               :class="'added-to-manifest-message-' + key"
                               :key="key"
                            >
                                {{ value }}
                            </p>
                        </template>
                    </div>
                </div>

                <div class="section-footer">
                    <div class="container">
                        <div class="d-flex justify-content-between">
                            <router-link :to="{name: 'ShippingInboundDashboard'}" class="btn btn-secondary">
                                Back
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </main>

        <ConfirmationPopup v-if="showCreateManifestModal"
                           :message="`Create new manifest with manifest code ${manifestCode}?`"
                           @deny="closeCreateManifestModal()"
                           @confirm="scanManifest(true)"
        >
            <template #additional-content>
                <v-select
                    v-model="selectedLocationId"
                    :reduce="option => option.ID"
                    label="LABEL"
                    :filterable="true"
                    :searchable="true"
                    :clearable="false"
                    :options="availableLocations"
                >
                </v-select>
            </template>
        </ConfirmationPopup>
    </div>
</template>

<script>
import HeaderBar from '@/components/_HeaderBar.vue';
import InputFieldError from '@/components/_InputFieldError.vue';
import ConfirmationPopup from '@/components/_ConfirmationPopup.vue';
import vSelect from 'vue-select';

export default {
    components: {
        'HeaderBar': HeaderBar,
        'InputFieldError': InputFieldError,
        'ConfirmationPopup': ConfirmationPopup,
        'v-select': vSelect,
    },
    data() {
        return {
            parcelReference: null,
            isLoading: false,
            isLoadingCarrierLocations: false,
            isSaving: false,
            errors: {},
            manifest: null,
            manifestCode: null,
            scanManifestInputActive: false,
            recentlyAddedItems: [],
            showCreateManifestModal: false,
            selectedLocationId: null,
            availableLocations: []
        };
    },
    mounted() {
        /* Focus on the "Scan reference" input field, so the user can immediately enter a shipment reference on page load */
        this.$refs.scanReferenceInput.focus();
    },
    created() {
        this.getCarrierLocations();
    },
    methods: {
        scanReference() {
            this.isLoading = true;
            this.errors = {};
            this.clearData();

            /* Check if the reference is empty, or exists of only spaces */
            if (!this.parcelReference || /^\s*$/.test(this.parcelReference)) {
                this.errors = {parcelReference: 'Enter a parcel reference.'};
                this.isLoading = false;
                return false;
            }

            const url = new URL(this.$store.state.baseUrl + 'parcel-manifests/receive-parcel');
            const params = new URLSearchParams();
            params.append('Parcel[REFERENCE]', this.parcelReference);

            this.$http.post(url.href, params).then((data) => {
                this.isLoading = false;

                /*
                 * Response code 404 || 422:
                 * - Show the error message
                 *
                 * Response code 200:
                 * - The parcel can be received to a sorted manifest.
                 */

                if (data.data.code === 404 || data.data.code === 422) {
                    this.errors = {parcelReference: data.data.errors};
                } else {
                    this.scanManifestInputActive = true;

                    /* Focus on the "Scan manifest" input field after activating it. */
                    this.$nextTick(() => {
                        this.$refs.scanManifestInput?.focus();
                    });
                }
            });
        },
        scanManifest(confirmCreateManifest = false) {
            this.errors = {};
            this.isSaving = true;
            this.showCreateManifestModal = false;

            if (!this.manifestCode || /^\s*$/.test(this.manifestCode)) {
                this.errors = {manifestCode: 'Enter a manifest code!'};
                this.isSaving = false;
                return false;
            }

            const url = new URL(this.$store.state.baseUrl + 'parcel-manifests/receive-to-manifest-sorted');
            const params = new URLSearchParams();
            params.append('ParcelManifest[MANIFEST_CODE]', this.manifestCode);
            params.append('ParcelManifest[PARCEL_REFERENCE]', this.parcelReference);
            params.append('ParcelManifest[CONFIRM_CREATE]', confirmCreateManifest);

            if (this.selectedLocationId) {
                params.append('ParcelManifest[DESTINATION_LOCATION_ID]', this.selectedLocationId);
                params.append('ParcelManifest[DESTINATION_LOCATION_TYPE]', this.availableLocations[this.selectedLocationId].TYPE);
            }

            this.$http.post(url.href, params).then((data) => {
                this.isSaving = false;

                if (data.data.code === 422) {
                    if (data.data.errors.MANIFEST_CODE) {
                        /* Validation error */
                        this.errors = {manifestCode: data.data.errors.MANIFEST_CODE};
                    } else {
                        this.errors = {manifestCode: data.data.errors};
                    }

                    this.$nextTick(() => {
                        this.$refs.scanManifestInput.focus();
                    });
                } else if (data.data.code === 300) {
                    this.showCreateManifestModal = true;
                } else {
                    /* Add the item to the list of recently added items. Keep the list limited to 3 entries. */
                    this.recentlyAddedItems.unshift('Parcel ' + this.parcelReference + ' has been added to manifest ' + this.manifestCode);
                    this.recentlyAddedItems.length = Math.min(this.recentlyAddedItems.length, 3);

                    this.parcelReference = null;
                    this.clearData();

                    /* Focus on the "Scan reference" input field after activating it. */
                    this.$nextTick(() => {
                        this.$refs.scanReferenceInput.focus();
                    });
                }
            });
        },
        clearData() {
            this.manifestCode = null;
            this.scanManifestInputActive = false;
            this.selectedLocationId = null;
        },
        closeCreateManifestModal() {
            this.showCreateManifestModal = false;
            this.manifestCode = null;
            this.selectedLocationId = null;
            this.$refs.scanManifestInput.focus();
        },
        getCarrierLocations() {
            this.isLoadingCarrierLocations = true;

            const url = new URL(this.$store.state.baseUrl + 'locations/get-all-carrier-and-second-tier-warehouses');

            this.$http.get(url.href).then((data) => {
                this.availableLocations = data?.data ?? [];
            });
        }
    },
    watch: {
        parcelReference() {
            this.scanManifestInputActive = false;
            this.manifestCode = null;
        }
    }
}
</script>

<style scoped>
.added-to-manifest-message-0, .saving-message {
    font-size: 16px;
    margin-top: 30px;
    color: #1d2640;
}

.added-to-manifest-message-1 {
    font-size: 16px;
    color: #868686;
}

.added-to-manifest-message-2 {
    font-size: 16px;
    color: #bbb;
}
</style>
