<template>
    <div>
        <HeaderBar />

        <main class="main">
            <section class="section-dashboard">
                <div class="container">
                    <div class="d-flex flex-wrap justify-content-between mb-5">
                        <h2 class="section-title">
                            Shipping outbound
                        </h2>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <InputFieldError attribute="global" :errors="errors"/>
                        </div>
                    </div>

                    <template v-if="isLoadingSubmodules || isLoadingSelectedLocation">
                        Loading submodules...
                    </template>

                    <template v-else-if="(!canShipShipments && !canShipManifests) || !selectedLocation">
                        No submodules available.
                    </template>

                    <template v-else>
                        <div class="card-list sm">
                            <div class="card-item-wrapper">
                                <div class="card-item lg" v-if="canShipManifests">
                                    <router-link
                                        :to="{name: 'ShipParcelManifest', query: {type: 'sorted', carrierLocationId: carrierLocationId}}"
                                        class="card-item--link mb-3"
                                        :class="!carrierLocationId ? 'inactive' : ''"
                                    >
                                        <div class="card-item-img">
                                            <img :src="require('@/assets/images/colored-icons/receive.svg')" class="img-fluid"
                                                 alt="">
                                        </div>

                                        <span class="d-block pt-4">
                                            SHIP PARCEL MANIFESTS (SORTED)
                                        </span>
                                    </router-link>

                                    <template v-if="!isLoadingCarrierLocations && carrierLocationOptions.length === 0">
                                        No carrier destinations found
                                    </template>

                                    <model-list-select
                                        :list="carrierLocationOptions"
                                        option-value="ID"
                                        option-text="NAME"
                                        v-model="carrierLocationId"
                                        placeholder="Select carrier"
                                        required
                                        v-else
                                    >
                                    </model-list-select>
                                </div>
                            </div>

                            <div class="card-item-wrapper" v-if="canShipShipments">
                                <router-link :to="{name: 'ParcelShipmentOverview'}" class="card-item lg">
                                    <div class="card-item-img">
                                        <img :src="require('@/assets/images/colored-icons/receive.svg')" class="img-fluid"
                                             alt="">
                                    </div>

                                    <span class="d-block pt-4">
                                        PARCEL SHIPMENT OVERVIEW
                                    </span>
                                </router-link>
                            </div>
                        </div>
                    </template>
                </div>
            </section>

            <div class="scrollable-area">
                <div class="section-footer">
                    <div class="container">
                        <div class="d-flex justify-content-between">
                            <router-link :to="{name: 'ShippingModuleSelect'}" class="btn btn-secondary">
                                Back
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
    import HeaderBar from '@/components/_HeaderBar.vue';
    import InputFieldError from '@/components/_InputFieldError.vue';
    import {ModelListSelect} from 'vue-search-select';

    export default {
        name: 'ShippingOutboundDashboardPage',
        components: {
            'HeaderBar': HeaderBar,
            'InputFieldError': InputFieldError,
            'ModelListSelect': ModelListSelect,
        },
        data() {
            return {
                isLoadingSubmodules: false,
                isLoadingCarrierLocations: false,
                moduleAccess: {},
                canShipManifests: false,
                canShipShipments: false,
                isLoadingSelectedLocation: false,
                selectedLocation: null,
                errors: {},
                carrierLocationOptions: [],
                carrierLocationId: null,
            };
        },
        created() {
            this.isLoadingSubmodules = true;

            this.getSelectedLocation();
            this.getCarrierLocations();
        },
        mounted() {
            this.$root.$on('switchedLocation', () => {
                this.errors = {};
                this.getSelectedLocation();
            });

            this.$root.$on('loadedRoleModuleAccess', () => {
                this.isLoadingSubmodules = false;
                this.moduleAccess = this.$store.state.roleAccess?.['role-module-access']?.[this.$store.state.users.currentUser.ROLE_ID]?.['shipping']?.['outbound'];
                this.canShipManifests = typeof this.moduleAccess['ship-manifests'] !== 'undefined' && this.moduleAccess['ship-manifests'].ACCESS;
                this.canShipShipments = typeof this.moduleAccess['ship-shipments'] !== 'undefined' && this.moduleAccess['ship-shipments'].ACCESS;
            });
        },
        methods: {
            goToSubmodule(submodule) {
                if (!this.selectedLocation) {
                    this.errors = {global: 'Select a location.'};
                    return false;
                }

                this.$router.push({
                    name: submodule,
                });
            },
            getSelectedLocation() {
                this.isLoadingSelectedLocation = true;

                this.$store.dispatch('userSettings/getSelectedLocation', module).then((data) => {
                    this.isLoadingSelectedLocation = false;
                    this.selectedLocation = data;
                });
            },
            getCarrierLocations() {
                this.isLoadingCarrierLocations = true;

                const url = new URL(this.$store.state.baseUrl + 'locations/get-all');
                url.searchParams.set('locationType', 'carrier');

                this.$http.get(url.href).then((data) => {
                    this.isLoadingCarrierLocations = false;
                    this.carrierLocationOptions = data?.data ?? [];
                });
            }
        }
    }
</script>

<style scoped>
    .card-item--link.inactive {
        filter: grayscale(60%);
        opacity: 0.3;
        pointer-events: none;
    }

    .card-item--link {
        color: #1d2640;
    }

    .card-item--link:hover {
        text-decoration: none;
        color: #4a90e2;
    }
</style>
