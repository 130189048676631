<template>
    <div>
        <HeaderBar title="Module select"></HeaderBar>

        <main class="main">

            <div class="scrollable-area">
                <div class="scrollable-content">
                    <div class="container">
                        <form v-on:submit.prevent="scanManifest()">
                            <div class="form-group mt-5">
                                <label for="rma-input" class="label-title">
                                    Manifest number
                                </label>

                                <input type="text" placeholder="Scan manifest number" class="form-control lg"
                                       :disabled="isLoadingManifest || isSaving || isMarkingManifestReadyToShip"
                                       v-model="manifestCode" ref="scanManifestInput">
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <InputFieldError attribute="manifestCode" :errors="errors"/>
                                </div>

                                <div class="col-12" v-if="isLoadingManifest">
                                    <p>Loading manifest {{ manifestCode }}...</p>
                                </div>

                                <div class="col-12" v-if="isCreatingManifest">
                                    <p>Creating manifest {{ manifestCode }}...</p>
                                </div>
                            </div>
                        </form>

                        <div class="form-group mt-2 mb-4">
                            <label class="checkbox-container">
                                Mark manifest as ready to ship

                                <input type="checkbox" v-model="manifestIsReadyToShip">
                                <span class="checkmark"></span>
                            </label>
                        </div>

                        <form v-on:submit.prevent="receiveReturnToManifest()">
                            <div class="form-group">
                                <label for="rma-input" class="label-title">
                                    Scan to receive return to manifest
                                </label>

                                <input type="text" placeholder="Scan RMA number or tracking code" class="form-control lg"
                                       id="rma-input" v-model="returnReference" ref="scanRmaInput" :disabled="showUseExistingManifestModal || !manifest || isSaving || isMarkingManifestReadyToShip">
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <InputFieldError attribute="returnReference" :errors="errors"/>
                                </div>
                            </div>
                        </form>

                        <p class="saving-message" v-if="isSaving">
                            Adding return {{ returnReference }} to manifest {{ manifest.MANIFEST_CODE }}...
                        </p>

                        <p class="saving-message" v-if="isMarkingManifestReadyToShip">
                            Marking manifest {{ manifest.MANIFEST_CODE }} as ready to ship...
                        </p>

                        <template v-if="returnsAddedToManifest">
                            <p v-for="(entry, key) in returnsAddedToManifest" :class="'added-return-message added-return-message-' + key" :key="key">
                                {{ entry }}
                            </p>
                        </template>
                    </div>
                </div>

                <div class="section-footer">
                    <div class="container">
                        <div class="d-flex justify-content-between">
                            <button type="button" class="btn btn-secondary" @click="previous()">
                                Back
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </main>

        <ConfirmationPopup v-if="showConfirmationModal"
                           :message="confirmationModalText"
                           @deny="closeReceiveReturnModal()" @confirm="receiveReturnToManifest(true)"></ConfirmationPopup>

        <ConfirmationPopup v-if="showCreateManifestModal"
                           :message="'Create new manifest with manifest code ' + manifestCode + '?'"
                           @deny="closeManifestModal()" @confirm="createNewManifest()"></ConfirmationPopup>

        <ConfirmationPopup v-if="showUseExistingManifestModal"
                           :message="'Use existing manifest ' + manifest.MANIFEST_CODE + ' with ' + Object.keys(manifest.unshippedReturnManifestRows).length + ' returns?'"
                           @deny="closeManifestModal()" @confirm="confirmUseExistingManifest()"></ConfirmationPopup>

        <ConfirmationPopup v-if="showCreateUnspecifiedReturnModal"
                           :message="'The return data is not available. Do you want to register an unspecified return?'"
                           @deny="closeCreateUnspecifiedReturnModal()" @confirm="confirmCreateUnspecifiedReturn()"></ConfirmationPopup>
    </div>
</template>

<script>
    import HeaderBar from '@/components/_HeaderBar.vue';
    import InputFieldError from '@/components/_InputFieldError.vue';
    import ConfirmationPopup from '@/components/_ConfirmationPopup.vue';
    import _ from 'lodash';

    export default {
        components: {
            'HeaderBar': HeaderBar,
            'InputFieldError': InputFieldError,
            'ConfirmationPopup': ConfirmationPopup,
        },
        data() {
            return {
                isLoadingManifest: false,
                isPreloadingManifest: false,
                isCreatingManifest: false,
                isSaving: false,
                isMarkingManifestReadyToShip: false,
                errors: {},
                returnReference: null,
                manifest: null,
                manifestId: this.$route.query.manifestId,
                manifestCode: null,
                manifestIsReadyToShip: false,
                recentlyAddedReturns: [],
                selectedLocation: null,
                showConfirmationModal: false,
                showCreateManifestModal: false,
                showUseExistingManifestModal: false,
                showCreateUnspecifiedReturnModal: false,
                confirmationModalText: null,
            };
        },
        mounted() {
            /* Focus on the "Scan manifest" input field, so the user can immediately enter a manifest code on page load */
            this.$refs.scanManifestInput.focus();
        },
        created() {
            this.$store.dispatch('userSettings/getSelectedLocation').then((data) => {
                if (data && data.value) {
                    this.selectedLocation = data.value;

                    if (this.manifestId) {
                        this.preloadManifest();
                    }
                }
            });
        },
        methods: {
            preloadManifest() {
                this.manifest = null;
                this.isLoadingManifest = true;
                this.isPreloadingManifest = true;

                const url = new URL(this.$store.state.baseUrl + 'return-manifests/get-return-manifest');
                url.searchParams.append('manifestCode', this.manifestId);
                url.searchParams.append('locationId', this.selectedLocation);

                this.$http.get(url.href).then((data) => {
                    this.isLoadingManifest = false;

                    if (data.data.code === 422) {
                        this.errors = {manifestCode: data.data.errors};
                        this.isPreloadingManifest = false;
                    } else {
                        this.manifest = data.data.data;
                        this.manifestCode = this.manifest.MANIFEST_CODE;

                        this.$nextTick(() => {
                            this.isPreloadingManifest = false;
                            this.$refs.scanRmaInput.focus();
                        });
                    }
                });
            },
            scanManifest() {
                this.manifest = null;

                /* Check if the manifest code is empty, or exists of only spaces */
                if (!this.manifestCode || /^\s*$/.test(this.manifestCode)) {
                    this.errors = {manifestCode: 'Enter a manifest code!'};
                    this.isSaving = false;
                    return false;
                }

                this.isLoadingManifest = true;

                const url = new URL(this.$store.state.baseUrl + 'return-manifests/get-return-manifest');
                url.searchParams.append('manifestCode', this.manifestCode);
                url.searchParams.append('locationId', this.selectedLocation);

                this.$http.get(url.href).then((data) => {
                    this.isLoadingManifest = false;
                    this.manifest = data.data.data;

                    if (this.manifest) {
                        this.showUseExistingManifestModal = true;
                    } else {
                        this.showCreateManifestModal = true;
                    }
                });
            },
            receiveReturnToManifest(confirm = false) {
                this.showConfirmationModal = false;
                this.errors = {};
                this.isSaving = true;

                /* Check if the return reference is empty, or exists of only spaces */
                if (!this.returnReference || /^\s*$/.test(this.returnReference)) {
                    this.errors = {returnReference: 'Enter a return reference!'};
                    this.isSaving = false;
                    return false;
                }

                let url = new URL(this.$store.state.baseUrl + 'return-manifests/receive-return-to-manifest');
                let params = new URLSearchParams();

                params.append('ReturnManifest[RETURN_REFERENCE]', this.returnReference);
                params.append('ReturnManifest[RECEIVE_CONFIRM]', confirm);
                params.append('ReturnManifest[LOCATION_ID]', this.selectedLocation);
                params.append('ReturnManifest[MANIFEST_CODE]', this.manifest.MANIFEST_CODE);
                params.append('ReturnManifest[SORTING_TYPE]', 'unsorted');

                this.$http.post(url.href, params).then((data) => {
                    this.isSaving = false;

                    /*
                     * Response code 422:
                     * - If the return was "Not found", give the operator the option to create an unspecified return
                     * - Otherwise show the error message
                     *
                     * Response code 300:
                     * - The operator has to confirm something before the return will be received.
                     *
                     * Response code 303:
                     * - Route 2 if the return is known in Returnbird (IS_UNSPECIFIED == 0).
                     * - Route 4 if the return is not known in Returnbird (IS_UNSPECIFIED == 1).
                     *
                     * Response code 100:
                     * - Route 1: the return has been received.
                     */

                    if (data.data.code === 422) {
                        if (data.data.message === 'Not found') {
                            /* If the return was not found, ask if the operator wants to receive it as an unspecified return. */
                            this.showCreateUnspecifiedReturnModal = true;
                        } else {
                            /* If the return was found, show the error. */
                            if (data.data.errors.MANIFEST_CODE) {
                                /* Validation error */
                                this.errors = {manifestCode: data.data.errors.MANIFEST_CODE};
                            } else {
                                this.errors = {manifestCode: data.data.errors};
                            }
                        }
                    } else if (data.data.code === 300) {
                        this.showConfirmationModal = true;
                        this.confirmationModalText = data.data.message;
                    } else if (data.data.code === 303) {
                        if (data.data.data.IS_UNSPECIFIED) {
                            /* Check & Sort the unspecified return (unknown in Returnbird) */
                            this.$router.push({
                                name: 'RegisterUnspecifiedReturnProducts',
                                query: {returnPk: data.data.data.RETURN_PK, returnReference: this.returnReference, manifestId: this.manifest.ID, manifestCode: this.manifest.MANIFEST_CODE, readyToShip: this.manifestIsReadyToShip}
                            });
                        } else {
                            /* Check & Sort the return (known in Returnbird) */
                            this.$router.push({
                                name: 'CheckReturnAssetOverview',
                                query: {returnId: data.data.data.RETURN_ID, manifestId: this.manifest.ID, manifestCode: this.manifest.MANIFEST_CODE, readyToShip: this.manifestIsReadyToShip}
                            });
                        }
                    } else {
                        /* Add the return to the list of recently added returns. Keep the list limited to 3 entries. */
                        this.$store.commit('manifests/addReturnToManifest', [this.manifest.MANIFEST_CODE, this.returnReference]);

                        if (this.manifestIsReadyToShip) {
                            this.setManifestReadyToShip();
                        } else {
                            this.returnReference = null;
                            this.clearData();

                            /* Focus on the "Scan RMA" input field after activating it. */
                            this.$nextTick(() => {
                                this.$refs.scanRmaInput.focus();
                            });
                        }
                    }
                });
            },
            clearData() {
                this.returnReference = null;
            },
            previous() {
                this.$router.push({
                    name: 'Inbound',
                });
            },
            closeReceiveReturnModal() {
                this.showConfirmationModal = false;
                this.returnReference = null;

                this.$refs.scanRmaInput.focus();
            },
            closeManifestModal() {
                this.showCreateManifestModal = false;
                this.showUseExistingManifestModal = false;
                this.showConfirmationModal = false;

                this.returnReference = null;
                this.manifest = null;
                this.manifestCode = null;

                this.$refs.scanManifestInput.focus();
            },
            closeCreateUnspecifiedReturnModal() {
                this.showCreateUnspecifiedReturnModal = false;
                this.returnReference = null;

                this.$refs.scanRmaInput.focus();
            },
            createNewManifest() {
                this.showCreateManifestModal = false;
                this.isCreatingManifest = true;

                const url = new URL(this.$store.state.baseUrl + 'return-manifests/create-unsorted-manifest');
                const params = new URLSearchParams();

                params.append('ReturnManifest[MANIFEST_CODE]', this.manifestCode);

                this.$http.post(url.href, params).then((data) => {
                    this.isCreatingManifest = false;

                    if (data.data.code === 422) {
                        this.errors = {manifestCode: data.data.errors};
                        return false;
                    } else {
                        this.showCreateContainerModal = false;
                        this.manifest = data.data.data;

                        this.$nextTick(() => {
                            this.$refs.scanRmaInput.focus();
                        });
                    }
                });
            },
            confirmUseExistingManifest() {
                this.showUseExistingManifestModal = false;

                this.$nextTick(() => {
                    this.$refs.scanRmaInput.focus();
                });
            },
            confirmCreateUnspecifiedReturn() {
                this.$router.push({
                    name: 'CreateUnspecifiedReturn',
                    params: {flow: 'manifest'},
                    query: {returnReference: this.returnReference, manifestId: this.manifest.ID, manifestCode: this.manifest.MANIFEST_CODE, readyToShip: this.manifestIsReadyToShip}
                });
            },
            setManifestReadyToShip() {
                this.isMarkingManifestReadyToShip = true;

                const url = new URL(this.$store.state.baseUrl + 'return-manifests/set-manifest-ready-to-ship');
                const params = new URLSearchParams();

                params.append('ReturnManifest[ID]', this.manifest.ID);
                params.append('ReturnManifest[IS_READY_TO_SHIP]', 1);

                this.$http.post(url.href, params).then((data) => {
                    this.isMarkingManifestReadyToShip = false;

                    if (data.data.code === 422) {
                        this.errors = data.data.errors;
                    } else {
                        this.recentlyAddedReturns.unshift('Manifest ' + this.manifest.MANIFEST_CODE + ' has been marked as ready to ship.');
                        this.recentlyAddedReturns.length = Math.min(this.recentlyAddedReturns.length, 3);

                        this.returnReference = null;
                        this.manifest = null;
                        this.manifestCode = null;
                        this.manifestIsReadyToShip = false;

                        this.$nextTick(() => {
                            this.$refs.scanManifestInput.focus();
                        });
                    }
                });
            },
        },
        computed: {
            returnsAddedToManifest() {
                let result = [];

                if (this.$store.state.manifests.activityLog) {
                    _.forOwn(this.$store.state.manifests.activityLog, (message) => {
                        result.unshift(message);
                        result.length = Math.min(result.length, 3);
                    });
                }

                return result;
            }
        },
        watch: {
            manifestCode() {
                /* Unset this.manifest to force the operator to load the new manifest after changing the manifest code. */
                if (this.manifest && !this.isPreloadingManifest) {
                    this.manifest = null;
                }
            }
        }
    }
</script>

<style scoped>
    .added-return-message {
        display: none;
    }

    .added-return-message-0, .saving-message {
        display: block;
        margin-top: 20px;
        color: #1d2640;
    }

    .added-return-message-1 {
        display: block;
        color: #868686;
    }

    .added-return-message-2 {
        display: block;
        color: #bbb;
    }
</style>
