<template>
    <div>
        <HeaderBar/>

        <main class="main">
            <form action="" @submit.prevent>
                <div class="scrollable-area">
                    <div class="scrollable-content">
                        <div class="container">
                            <div class="row" v-if="isLoadingParcelShipment">
                                <div class="col-12">
                                    Loading parcel shipment...
                                </div>
                            </div>

                            <div class="row" v-else-if="!parcelShipment || Object.keys(parcelShipment).length === 0">
                                <div class="col-12">
                                    Parcel shipment not found.
                                </div>
                            </div>

                            <table class="table-first-level" v-else>
                                <thead>
                                    <tr>
                                        <th>
                                            Shipment
                                        </th>

                                        <th>
                                            Type
                                        </th>

                                        <th>
                                            Parcel manifests
                                        </th>

                                        <th>
                                            Shipping destination
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td colspan="5">
                                            <table class="table-second-level">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            {{ parcelShipment.REFERENCE }}
                                                        </th>

                                                        <th>
                                                            {{ getTypeLabel(parcelShipment.TYPE) }}
                                                        </th>

                                                        <th>
                                                            {{ Object.keys(parcelShipment?.parcelManifests).length }}
                                                        </th>

                                                        <th>
                                                            {{ parcelShipment.DESTINATION_NAME }}
                                                        </th>
                                                    </tr>
                                                </thead>

                                                <ParcelManifestShipmentBody :parcel-shipment="parcelShipment" :errors="errors"/>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div><!-- scrollable-content -->

                    <div class="section-footer">
                        <div class="container">
                            <div class="d-flex justify-content-between align-items-center">
                                <button type="button" class="btn btn-secondary" @click="previous()">
                                    Back
                                </button>

                                <div v-if="isShippingParcelShipment">
                                    Sending shipment...
                                </div>

                                <button type="button" class="btn btn-primary" @click="sendShipment()"
                                        :disabled="isShippingParcelShipment"
                                        v-if="parcelShipment && Object.keys(parcelShipment).length > 0 && Object.keys(parcelShipment?.parcelManifests).length > 0"
                                >
                                    Send shipment
                                </button>
                            </div>
                        </div>
                    </div><!-- section-footer -->
                </div><!-- scrollable-area -->
            </form>
        </main>
    </div>
</template>

<script>
import HeaderBar from '@/components/_HeaderBar.vue';
import ParcelManifestShipmentBody from '@/components/shipping/_ParcelManifestShipmentBody.vue';

export default {
    components: {
        'HeaderBar': HeaderBar,
        'ParcelManifestShipmentBody': ParcelManifestShipmentBody,
    },
    data() {
        return {
            errors: {},
            parcelShipmentReference: this.$route.query.reference,
            isLoadingParcelShipment: false,
            isShippingParcelShipment: false,
            parcelShipment: null,
        };
    },
    created() {
        this.getParcelShipment();
    },
    methods: {
        previous() {
            if (this.$route.query.origin) {
                if (this.$route.query.origin === 'shipSorted') {
                    this.$router.push({
                        name: 'ShipParcelManifest',
                        query: {
                            type: 'sorted',
                            carrierLocationId: this.parcelShipment.DESTINATION_CARRIER_LOCATION_ID
                        }
                    });
                    return;
                } else if (this.$route.query.origin === 'shipUnsorted') {
                    this.$router.push({
                        name: 'ShipParcelManifest',
                        query: {
                            type: 'unsorted'
                        }
                    });
                    return;
                }
            }

            this.$router.push({
                name: 'ParcelShipmentOverview',
            });
        },
        getParcelShipment() {
            this.isLoadingParcelShipment = true;

            const url = new URL(this.$store.state.baseUrl + 'parcel-shipments/get-parcel-shipment');
            url.searchParams.set('reference', this.parcelShipmentReference);

            this.$http.get(url.href).then((data) => {
                this.isLoadingParcelShipment = false;

                if (data.data) {
                    this.parcelShipment = data.data;
                }
            });
        },
        sendShipment() {
            this.errors = {};
            this.isShippingParcelShipment = true;

            const url = new URL(this.$store.state.baseUrl + 'parcel-shipments/ship-parcel-manifest-shipment');
            const params = new URLSearchParams();
            params.append('ParcelShipment[ID]', this.parcelShipment.ID);

            this.$http.post(url.href, params).then((data) => {
                this.isShippingParcelShipment = false;

                if (data.data.code === 100) {
                    this.$store.commit('showSuccessMessage', 'The shipment has been sent.');
                    this.getParcelShipmentPdf();
                    this.previous();
                } else if (data.data.code === 422) {
                    this.errors = {parcelShipment: data.data.errors};
                }
            });
        },
        getParcelShipmentPdf() {
            this.errors = {};
            this.isGeneratingDocument = true;

            const url = new URL(this.$store.state.baseUrl + 'pdf/outbound-parcel-shipment');
            url.searchParams.append('id', this.parcelShipment.ID);

            this.$http.get(url.href, {responseType: 'blob'}).then((data) => {
                this.isGeneratingDocument = false;

                const documentBlob = new Blob([data.data], {type: 'application/pdf'});
                window.open(URL.createObjectURL(documentBlob));
            });
        },
        getTypeLabel(type) {
            switch (type) {
                case 'sorted-parcel-manifest':
                    return 'Sorted parcel manifest';

                case 'unsorted-parcel-manifest':
                    return 'Unsorted parcel manifest';

                default:
                    return '';
            }
        }
    },
}
</script>

<style scoped>
.action-btn {
    padding: 3px 9px;
    border: 1px solid #4a90e2;
    text-decoration: none;
    border-radius: 5px;
}

.action-btn:hover {
    color: #fff;
    background-color: #4a90e2;
}

.collapsed > .when-open, .not-collapsed > .when-closed {
    display: none;
}

.action-link {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}

.action-link:hover {
    color: #0056b3;
    text-decoration: underline;
}
</style>
